exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-restik-pos-js": () => import("./../../../src/pages/apps/restik-pos.js" /* webpackChunkName: "component---src-pages-apps-restik-pos-js" */),
  "component---src-pages-automation-fast-fud-js": () => import("./../../../src/pages/automation/fast-fud.js" /* webpackChunkName: "component---src-pages-automation-fast-fud-js" */),
  "component---src-pages-automation-index-js": () => import("./../../../src/pages/automation/index.js" /* webpackChunkName: "component---src-pages-automation-index-js" */),
  "component---src-pages-automation-kafe-index-js": () => import("./../../../src/pages/automation/kafe/index.js" /* webpackChunkName: "component---src-pages-automation-kafe-index-js" */),
  "component---src-pages-automation-kafe-programma-ucheta-js": () => import("./../../../src/pages/automation/kafe/programma-ucheta.js" /* webpackChunkName: "component---src-pages-automation-kafe-programma-ucheta-js" */),
  "component---src-pages-automation-kofejnya-js": () => import("./../../../src/pages/automation/kofejnya.js" /* webpackChunkName: "component---src-pages-automation-kofejnya-js" */),
  "component---src-pages-automation-obshchepit-js": () => import("./../../../src/pages/automation/obshchepit.js" /* webpackChunkName: "component---src-pages-automation-obshchepit-js" */),
  "component---src-pages-automation-restoran-js": () => import("./../../../src/pages/automation/restoran.js" /* webpackChunkName: "component---src-pages-automation-restoran-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-delivery-index-js": () => import("./../../../src/pages/delivery/index.js" /* webpackChunkName: "component---src-pages-delivery-index-js" */),
  "component---src-pages-delivery-kafe-js": () => import("./../../../src/pages/delivery/kafe.js" /* webpackChunkName: "component---src-pages-delivery-kafe-js" */),
  "component---src-pages-emenu-js": () => import("./../../../src/pages/emenu.js" /* webpackChunkName: "component---src-pages-emenu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kassa-dlya-kafe-i-restoranov-js": () => import("./../../../src/pages/kassa-dlya-kafe-i-restoranov.js" /* webpackChunkName: "component---src-pages-kassa-dlya-kafe-i-restoranov-js" */),
  "component---src-pages-offers-offer-10-103292-f-9-3-a-6-e-419-f-bf-9-d-045829-d-6876-c-js": () => import("./../../../src/pages/offers/offer-10-103292f9-3a6e-419f-bf9d-045829d6876c.js" /* webpackChunkName: "component---src-pages-offers-offer-10-103292-f-9-3-a-6-e-419-f-bf-9-d-045829-d-6876-c-js" */),
  "component---src-pages-offers-offer-3-f-2-34-d-58-ebb-a-16-f-4-a-8-a-94-f-1-f-92082629-b-5-f-js": () => import("./../../../src/pages/offers/offer-3f2-34d58ebb-a16f-4a8a-94f1-f92082629b5f.js" /* webpackChunkName: "component---src-pages-offers-offer-3-f-2-34-d-58-ebb-a-16-f-4-a-8-a-94-f-1-f-92082629-b-5-f-js" */),
  "component---src-pages-offers-offer-30-c-57-cdc-96-9194-4-ef-8-86-f-2-0097-d-39588-ee-js": () => import("./../../../src/pages/offers/offer-30-c57cdc96-9194-4ef8-86f2-0097d39588ee.js" /* webpackChunkName: "component---src-pages-offers-offer-30-c-57-cdc-96-9194-4-ef-8-86-f-2-0097-d-39588-ee-js" */),
  "component---src-pages-offers-offer-50-c-8-b-05-dce-c-2-b-9-4-cea-9258-8-ffa-52487236-js": () => import("./../../../src/pages/offers/offer-50-c8b05dce-c2b9-4cea-9258-8ffa52487236.js" /* webpackChunkName: "component---src-pages-offers-offer-50-c-8-b-05-dce-c-2-b-9-4-cea-9258-8-ffa-52487236-js" */),
  "component---src-pages-offers-offer-70-470-e-87-c-1-d-879-40-f-2-b-8-ba-f-1851364-d-3-a-7-js": () => import("./../../../src/pages/offers/offer-70-470e87c1-d879-40f2-b8ba-f1851364d3a7.js" /* webpackChunkName: "component---src-pages-offers-offer-70-470-e-87-c-1-d-879-40-f-2-b-8-ba-f-1851364-d-3-a-7-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-test-test-b-53-de-133-2349-4224-b-8-c-9-c-8-be-1-b-250-f-73-js": () => import("./../../../src/pages/test/test-b53de133-2349-4224-b8c9-c8be1b250f73.js" /* webpackChunkName: "component---src-pages-test-test-b-53-de-133-2349-4224-b-8-c-9-c-8-be-1-b-250-f-73-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */)
}

